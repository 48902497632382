import { createAction, ActionType } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { Deal, DealShortInfo } from '../types/amr-pipeline/models/Deal';
import { DealDetails } from '../types/amr-pipeline/models/DealDetails';
import { DealSave } from '../types/deals/DealSave';
import { Tranche } from '../types/amr-pipeline/models/Tranche';
import { Security } from '../types/security/Security';
import { SecurityBwicStatisticsSummary } from '../types/security/SecurityBwicStatisticsSummary';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../types/state/AppState';
import { AnyAction } from 'redux-saga';
import { GridDataItem } from '../types/state/GridState';
import { gridActions } from '.';
import { clone, isNil } from 'lodash';
import { TrancheStatus } from '../types/amr-pipeline/enums/TrancheStatus';
import { SettlementInstructions } from '../types/amr-pipeline/models/SettlementInstructions';
import { AmrClass } from '../types/amr-pipeline/models/AmrClass';
import { AmrInfoSaveForm } from '../types/deals/AmrInfoSaveForm';
import { amrFormatUtils, formatUtils } from '../utils';
import { Rating } from '../types/enums/Rating';
import { DealDocumentsWithPattern } from '../types/amr-pipeline/models/DealDocumentsWithPattern';

const getDeals = createAction('deals/get/REQUEST', resolve => (searchTerm?: string) => resolve({ searchTerm }));

const getDealsResult = createAction(
    'deals/get/REQUEST_RESULT',
    resolve => (deals: DealShortInfo[]) => resolve({ deals }),
);

const getDealDetails = createAction(
    'deals/getOne/REQUEST',
    resolve => (referenceName: string) => resolve({ referenceName }),
);

const getDealDetailsResult = createAction(
    'deals/getOne/REQUEST_RESULT',
    resolve => (referenceName: string, dealDetails: DealDetails) =>
        resolve({
            referenceName,
            dealDetails,
        }),
);

const getClassSecondaryInfo = createAction(
    'deals/GET_CLASS_SECONDARY_INFO',
    resolve => (selectedClass: Tranche) => resolve({ selectedClass }),
);

const getClassSecondaryInfoResult = createAction(
    'deals/GET_CLASS_SECONDARY_INFO_RESULT',
    resolve => (security?: Security, securityStatistics?: SecurityBwicStatisticsSummary) =>
        resolve({ security, securityStatistics }),
);

const resetDeals = createAction('deals/RESET_DEALS');

const editDeal = createAction('deals/EDIT');
const editDealReset = createAction('deals/EDIT_RESET');

const updateDeal = createAction(
    'deals/update/REQUEST',
    resolve => (referenceName: string, formData: DealSave) =>
        resolve({
            referenceName,
            formData,
        }),
);

const updateDealResult = createAction(
    'deals/update/RESULT',
    resolve => (requestState: RequestState) =>
        resolve({
            requestState,
        }),
);

const deleteDealRequest = createAction(
    'deals/delete/REQUEST',
    resolve => (referenceName: string) => resolve({ referenceName }),
);

const deleteDealResult = createAction(
    'deals/delete/RESULT',
    resolve => (requestState: RequestState, referenceName: string) =>
        resolve({
            requestState,
            referenceName,
        }),
);

const allClassesEdit = createAction('deals/allClasses/edit', resolve => (classes: Tranche[]) => resolve({ classes }));

const allClassesEditInit = createAction('deals/allClasses/edit/init', resolve => (deal: Deal) => resolve({ deal }));

const allClassesUpdate = createAction(
    'deals/allClasses/update/REQUEST',
    resolve => (dealReferenceName: string) => resolve({ dealReferenceName }),
);

const allClassesUpdateResponse = createAction('deals/allClasses/update/RESPONSE');

const allClassesCacheTranche = createAction(
    'deals/allClasses/edit/CACHE_TRANCHE',
    resolve => (rowIndex: number, tranche: Tranche) => resolve({ rowIndex, tranche }),
);

const allClassesInvalidateTranceCache = createAction(
    'deals/allClasses/edit/INVALIDATE_TRANCHE_CACHE',
    resolve => (rowIndex?: number) => resolve({ rowIndex }),
);

const updateGridRow = (rowIndex: number, tranche: Tranche) => {
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        dispatch(gridActions.deleteRow(rowIndex));
        dispatch(gridActions.insertDataItems([tranche], rowIndex));
    };
};

const allClassesUpdateTickers = (rowIndex: number) => {
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        const dataItems: GridDataItem<Tranche>[] = getState().grid.dataItems;
        const selectedDeal = getState().deals.selectedDeal;
        const dataItem = dataItems[rowIndex];

        if (isNil(dataItem) || isNil(selectedDeal)) {
            return;
        }

        const updatedDataItem = {
            ...dataItem,
            ticker144A: formatUtils.formatTickerRule144a(selectedDeal.ticker, dataItem.name, dataItem.rating as Rating),
            tickerRegS: formatUtils.formatTickerRuleRegS(selectedDeal.ticker, dataItem.name, dataItem.rating as Rating),
            tickerAccdInvCertif: formatUtils.formatTickerRuleAccInvCertif(selectedDeal.ticker, dataItem.name, dataItem.rating as Rating),
        }

        dispatch(updateGridRow(rowIndex, updatedDataItem));
        dispatch(allClassesInvalidateTranceCache(rowIndex));
    };
}

const allClassesUpdateStatus = (rowIndex: number) => {
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        const dataItems: GridDataItem<Tranche>[] = getState().grid.dataItems;

        if (isNil(dataItems[rowIndex])) {
            return;
        }

        const dataItem = clone(dataItems[rowIndex]);

        if (dataItem.status === TrancheStatus.Inactive) {
            dispatch(allClassesCacheTranche(rowIndex, dataItem));

            const updatedTranche: Tranche = {
                ...dataItem,
                balance: 0,
                margin: 0,
                redeemed: true,
                floaterIndex: undefined,
                coupon: undefined,
                ratingDbrs: undefined,
                ratingFitch: undefined,
                ratingKbra: undefined,
                ratingMoodys: undefined,
                ratingSnP: undefined,
            };

            dispatch(updateGridRow(rowIndex, updatedTranche));
        } else {
            const { hasHistoryData } = dataItem;
            let updatedTranche: Tranche;

            if (hasHistoryData) {
                const {
                    historyBalance,
                    historyMargin,
                    historyFloaterIndex,
                    historyRatingDbrs,
                    historyRatingFitch,
                    historyRatingKbra,
                    historyRatingMoodys,
                    historyRatingSnP,
                } = dataItem;

                updatedTranche = {
                    ...dataItem,
                    balance: historyBalance,
                    margin: historyMargin,
                    floaterIndex: historyFloaterIndex,
                    ratingDbrs: historyRatingDbrs,
                    ratingFitch: historyRatingFitch,
                    ratingKbra: historyRatingKbra,
                    ratingMoodys: historyRatingMoodys,
                    ratingSnP: historyRatingSnP,
                    ...(dataItem.status === TrancheStatus.Active && {
                        redeemed: false,
                    }),
                };
            } else {
                const cachedItem = getState().deals.allClasses.cachedInactiveClasses[rowIndex];

                if (!cachedItem && dataItem.status === TrancheStatus.Draft) {
                    return;
                }

                let cachedData;

                if (cachedItem) {
                    const {
                        balance,
                        margin,
                        redeemed,
                        floaterIndex,
                        coupon,
                        ratingDbrs,
                        ratingFitch,
                        ratingKbra,
                        ratingMoodys,
                        ratingSnP,
                    } = cachedItem;

                    cachedData = {
                        balance,
                        margin,
                        redeemed,
                        floaterIndex,
                        coupon,
                        ratingDbrs,
                        ratingFitch,
                        ratingKbra,
                        ratingMoodys,
                        ratingSnP,
                    };
                }

                updatedTranche = {
                    ...dataItem,
                    ...cachedData,
                    ...(dataItem.status === TrancheStatus.Active && {
                        redeemed: false,
                    }),
                };
            }

            dispatch(updateGridRow(rowIndex, updatedTranche));
            dispatch(allClassesInvalidateTranceCache(rowIndex));
        }
    };
};

const allClassesUpdateCoupon = (rowIndex: number) => {
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        const dataItems: GridDataItem<Tranche>[] = getState().grid.dataItems;

        if (isNil(dataItems[rowIndex])) {
            return;
        }

        const dataItem = clone(dataItems[rowIndex]);
        const {
            floaterIndex,
            margin,
            originalFloaterIndex,
            originalMargin
        } = dataItem;

        const updatedTrahcne: Tranche = {
            ...dataItem,
            coupon: amrFormatUtils.formatInventoryCoupon(floaterIndex, margin),
            originalCoupon: amrFormatUtils.formatInventoryCoupon(originalFloaterIndex, originalMargin),
        };

        dispatch(updateGridRow(rowIndex, updatedTrahcne));
    };
};

const updateDealDocuments = createAction(
    'deals/documents/update/REQUEST',
    resolve => (referenceName: string, documentsWithPattern: DealDocumentsWithPattern) =>
        resolve({
            referenceName,
            documentsWithPattern,
        }),
);

const updateDealDocumentsResult = createAction(
    'deals/documents/update/RESULT',
    resolve => (requestState: RequestState) =>
        resolve({
            requestState,
        }),
);

const amrInfoClassesEdit = createAction('deals/amrInfo/edit', resolve => (classes: AmrClass[]) => resolve({ classes }));

const amrInfoSave = createAction(
    'deals/amrInfo/save',
    resolve => (dealReferenceName: string, transactionReferenceName: string, amrInfo: AmrInfoSaveForm) =>
        resolve({ dealReferenceName, transactionReferenceName, amrInfo }),
);

const saveAmrInfoResponse = createAction('deals/amrInfo/save/RESPONSE');
const deleteAmrTransaction = createAction(
    'deals/amrInfo/delete',
    resolve => (dealReferenceName: string, transactionReferenceName: string) =>
        resolve({ dealReferenceName, transactionReferenceName }),
);

const saveExcludedDealersRequest = createAction(
    'deals/saveExcludedDealers/REQUEST',
    resolve =>
        (
            dealReferenceName: string,
            transactionReferenceName: string,
            classReferenceName: string,
            excludedDealersReferenceNames: string[],
        ) =>
            resolve({ dealReferenceName, transactionReferenceName, classReferenceName, excludedDealersReferenceNames }),
);

const saveExcludedDealersResponse = createAction('deals/saveExcludedDealers/RESPONSE');

const saveSettlementInstructionsRequest = createAction(
    'deals/saveSettlementInstructions/REQUEST',
    resolve =>
        (dealReferenceName: string, classReferenceName: string, settlementInstructions: SettlementInstructions) =>
            resolve({ dealReferenceName, classReferenceName, settlementInstructions }),
);

const saveSettlementInstructionsResponse = createAction('deals/saveSettlementInstructions/RESPONSE');

const exportSettlementInstructions = createAction(
    'deals/exportSettlementInstructions/REQUEST',
    resolve =>
        (
            dealReferenceName: string,
            dealLegalName: string,
            transactionReferenceName: string,
            classReferenceName: string,
            classLegalName: string,
        ) =>
            resolve({ dealReferenceName, dealLegalName, transactionReferenceName, classReferenceName, classLegalName }),
);

const openFigiErrorTickersResponse = createAction(
    'deals/openFigiErrorTickers/RESPONSE',
    resolve => (errorTickers: string[]) => resolve({ errorTickers }),
)

const reset = createAction('deals/RESET');

export type TDealsActions = ActionType<(typeof dealsActions)[keyof typeof dealsActions]>;

export const dealsActions = {
    getDeals,
    getDealsResult,
    getDealDetails,
    resetDeals,
    getDealDetailsResult,
    getClassSecondaryInfo,
    getClassSecondaryInfoResult,
    editDeal,
    editDealReset,
    updateDeal,
    updateDealResult,
    deleteDealRequest,
    deleteDealResult,
    allClassesEdit,
    allClassesEditInit,
    allClassesUpdate,
    allClassesUpdateResponse,
    allClassesCacheTranche,
    allClassesInvalidateTranceCache,
    allClassesUpdateStatus,
    allClassesUpdateCoupon,
    allClassesUpdateTickers,
    updateDealDocuments,
    updateDealDocumentsResult,
    saveExcludedDealersRequest,
    saveExcludedDealersResponse,
    saveSettlementInstructionsResponse,
    saveSettlementInstructionsRequest,
    amrInfoClassesEdit,
    amrInfoSave,
    saveAmrInfoResponse,
    deleteAmrTransaction,
    exportSettlementInstructions,
    openFigiErrorTickersResponse,
    reset,
};
